/*! roboto font */

@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.woff2') format('woff2'),
        url('Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('Inter-Bold.woff2') format('woff2'),
        url('Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'parimatchregularregular';
    src: url('pari-match_regular-webfont.woff2') format('woff2'),
         url('pari-match_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Inter-Light';
//     src: url('Inter-Light.woff2') format('woff2'),
//         url('Inter-Light.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }