body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.1s linear 0s;
  -moz-transition: color 0.1s linear 0s;
  -o-transition: color 0.1s linear 0s;
  transition: color 0.1s linear 0s;
}

a:hover,
a:active,
a:focus {
  outline: 0;
  color: #000;
  text-decoration: none;
}

input:focus,
textarea:focus,
input[type="text"]:focus {
  outline: 0;
}

input,
button,
select,
textarea {
  outline: 0;
}

.container {
  width: 100%;
}

body>iframe {
  pointer-events: none;
}

.csbar::-webkit-scrollbar {
  width: 8px;
}

.csbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #9b9b9b;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
}

.csbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 5px;
}